import React from 'react'

import addToMailchimp from 'gatsby-plugin-mailchimp'

export default class Subscribe extends React.Component {

  constructor() {
    super()
    this.state = {
      email: '',
      firstName: '',
      result: {},
      waiting: false
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ waiting: true })
    const { email, firstName } = this.state
    const result = await addToMailchimp(email, { FNAME: firstName })
    console.log(result)
    this.setState({ result, waiting: false })
  }

  render() {
    const success = this.state.result.result === 'success'
    const error = this.state.result.result === 'error' && this.state.result.msg
    const waiting = this.state.waiting
    return (
      <section className="subscriber-container">
        <h3 className="subscribe-text">Come on this journey with me.</h3>
        <form className="subscriber-form" onSubmit={(event) => this.handleSubmit(event)}>
          <div className="mc-field-group">
            <input placeholder="Enter your email" onChange={(event) => this.setState({ email: event.target.value })} type="email" value={this.state.email} name="EMAIL" className="required email" id="mce-EMAIL" />
          </div>
          <div className="mc-field-group">
            <input placeholder="Enter your first name" onChange={(event) => this.setState({ firstName: event.target.value })} type="text" value={this.state.firstName} name="FNAME" className="" id="mce-FNAME" />
          </div>
          <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true"><input type="text" name="b_5436d9c902f3e428822f8c077_f817f49397" tabindex="-1" value="" /></div>
          <div className="clear">
            <input disabled={waiting} style={{ background: success ? 'green' : "#3c3c3c", opacity: waiting ? 0.5 : 1 }} type="submit" value={success ? "Subscribed" : "Subscribe"} name="subscribe" id="mc-embedded-subscribe" className="button" />
          </div>
        </form>
        {error && <h4 className="subscribe-text" style={{color: 'red', 'font-size': '16px'}}>Email already taken</h4>}
        {success && <h4 className="subscribe-text" style={{color: 'green', 'font-size': '16px'}}>You're signed up! <span role="img" aria-label="tada emoji">🎉</span> A confirmation is on the way.</h4>}
      </section>
    )
  }
}
